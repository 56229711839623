<template>
  <div class="monitoring-report-page">
    <a-row type="flex" justify="space-between">
      <a-col :lg="10" :md="10" :xs="24">
        <h2 class="list-title">
          <a-icon type="audit" />
          <span>{{ labels.title }}</span>
        </h2>
        <p class="list-subtitle">{{ labels.subtitle }}</p>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <a-table
          v-if="customData.length"
          :loading="loading"
          :dataSource="customData"
          :pagination="false"
          :columns="columns"
          :rowKey="'key'"
          :scroll="{ x: true }"
          :defaultExpandAllRows="true"
          :expandIcon="expandIcon"
          :expandRowByClick="false"
          :rowClassName="getRowClassName"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import labels from "@/utils/labels";
import {
  DATE_FORMAT,
  adminPageTitle,
  generateUniqueID,
} from "../../../utils/utils";
import { mapActions, mapState } from "vuex";
import _ from "lodash";

let interval = null;

export default {
  name: "MonitoringReport",
  metaInfo: {
    title: adminPageTitle(labels.monitoringReport.title),
  },
  filters: {
    ucwords(value) {
      if (!value) return "";
      value = value.toString();
      return value
        .split(" ")
        .map((item) => _.capitalize(item))
        .join(" ");
    },
  },
  data() {
    return {
      labels: labels.monitoringReport,
      search: "",
      dataSource: [],
      loading: false,
    };
  },
  created() {
    this.handleSearch();
    interval = setInterval(() => {
      this.handleSearch();
    }, 30000);
  },
  methods: {
    ...mapActions("reports", ["fetchMonitoringReport"]),
    expandIcon({ expandable }) {
      return expandable ? "" : " ";
    },
    uuid() {
      return generateUniqueID();
    },
    getRowClassName(record) {
      return !record.isLottery
        ? "table-row-dark"
        : record.isLottery
        ? "table-row-bold"
        : "";
    },
    async handleSearch() {
      try {
        this.loading = true;
        const { data } = await this.fetchMonitoringReport();
        this.dataSource = [];
        this.$nextTick(() => {
          this.dataSource = data;
        });
      } catch (error) {
        this.$message.error(error.message);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapState("layout", ["screenWidth"]),
    isMobile() {
      return this.screenWidth < 600;
    },
    locale() {
      return {
        emptyText: this.labels.table.emptyText,
      };
    },
    customData() {
      if (this.isMobile) {
        return this.dataSource.map((item) => ({
          ...item,
          key: this.uuid(),
        }));
      }

      //NOTE: GROUP BY LOTTERY AND SECOND LOTTERY
      const lotteries = Object.values(
        _.cloneDeep(this.dataSource).reduce((acc, item) => {
          const lottery = item.lottery;
          const key = item.lottery_id;
          if (!acc[key]) {
            acc[key] = {
              key: this.uuid(),
              lottery,
              isLottery: true,
              children: [],
            };
          }
          acc[key].children.push({ ...item, key: this.uuid() });
          return acc;
        }, {})
      );
      return lotteries;
    },
    columns() {
      return [
        {
          title: this.labels.table.columns.date,
          dataIndex: "date",
          key: "date",
          customRender: (text, record) => {
            if (record?.isLottery) {
              return {
                children: <span class="text-hover">{record.lottery}</span>,
                attrs: {
                  colSpan: this.columns.filter((c) => !c.hidden).length,
                },
              };
            }
            return this.$moment(text).format("DD/MM/YYYY H:mm:ss A");
          },
          hidden: this.isMobile,
        },
        {
          title: this.labels.table.columns.type,
          dataIndex: "type",
          key: "type",
          hidden: this.isMobile,
          customRender: (text, record) => {
            if (record.isLottery) {
              return {
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return text;
          },
        },
        {
          title: this.labels.table.columns.numbers,
          dataIndex: "numbers",
          key: "numbers",
          hidden: this.isMobile,
          customRender: (text, record) => {
            if (record.isLottery) {
              return {
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return text;
          },
        },
        {
          title: this.labels.table.columns.percentage,
          dataIndex: "percentage",
          key: "percentage",
          align: "right",
          customRender: (text, record) => {
            if (record.isLottery) {
              return {
                attrs: {
                  colSpan: 0,
                },
              };
            }

            return `${this.$options.filters.formatMoney(
              text,
              "% ",
              2,
              ",",
              "."
            )}`;
          },
          hidden: this.isMobile,
        },
        {
          title: this.labels.table.columns.amount,
          dataIndex: "amount",
          key: "amount",
          align: "right",
          customRender: (text, record) => {
            if (record.isLottery) {
              return {
                attrs: {
                  colSpan: 0,
                },
              };
            }

            return `${this.$options.filters.formatMoney(
              text,
              "$ ",
              0,
              ",",
              "."
            )}`;
          },
          hidden: this.isMobile,
        },
        {
          title: this.labels.table.columns.amountToWin,
          dataIndex: "amount_to_win",
          key: "amount_to_win",
          align: "right",
          customRender: (text, record) => {
            if (record.isLottery) {
              return {
                attrs: {
                  colSpan: 0,
                },
              };
            }

            return `${this.$options.filters.formatMoney(
              text,
              "$ ",
              0,
              ",",
              "."
            )}`;
          },
          hidden: this.isMobile,
        },
        {
          title: this.labels.table.columns.plays,
          dataIndex: "plays",
          key: "plays",
          hidden: !this.isMobile,
          customRender: (_, record) => {
            return (
              <div class="list-item">
                <div class="list-item-head">
                  <div
                    class="list-item-head-header"
                    style={{ borderColor: "#000" }}
                  >
                    <span>
                      {this.$options.filters.ucwords(
                        this.$moment(record.date).format(
                          DATE_FORMAT.MOMENT_DAY_MONTH
                        )
                      )}
                    </span>
                    <small>
                      {this.$moment(record.date)
                        .format(DATE_FORMAT.MOMENT_TIME)
                        .toUpperCase()}
                    </small>
                  </div>
                  <div class="list-item-head-content">
                    <div class="list-item-head-content-lottery">
                      <span>{record.lottery}</span>
                    </div>
                    <div class="list-item-head-content-numbers">
                      <span>{record.numbers}</span>
                    </div>
                    <div class="list-item-head-content-type">
                      <span>{record.type}</span>
                    </div>
                  </div>
                  <div class="list-item-head-footer">
                    <div class="list-item-head-footer-amount">
                      <span class="danger">
                        {this.$options.filters.formatNumber(
                          record.amount_to_win,
                          0,
                          ",",
                          "."
                        )}
                      </span>
                    </div>
                    <div class="list-item-head-footer-amount">
                      <span class="success">
                        {this.$options.filters.formatNumber(
                          record.amount,
                          0,
                          ",",
                          "."
                        )}
                      </span>
                    </div>
                    <div class="list-item-head-footer-amount">
                      <span class="warning">
                        {this.$options.filters.formatMoney(
                          record.percentage,
                          "% ",
                          2,
                          ",",
                          "."
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          },
        },
      ].filter((column) => !column.hidden);
    },
  },
  beforeDestroy() {
    clearInterval(interval);
    interval = 0;
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.monitoring-report-page {
  .table-row-dark:nth-child(odd) {
    background-color: #fafafa !important;
  }
  .table-row-bold {
    font-weight: 600;
  }
  .list {
    &-title {
      margin-bottom: 10px;
      .list-icon {
        margin-right: 10px;
      }
    }
    &-subtitle {
      margin-bottom: 10px;
    }
  }
  .list-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    &-head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 6px;
      &-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;
        max-width: 25%;
        width: 25%;
        border-right: 4px solid color(border);
        .success {
          color: color(success);
        }
        .warning {
          color: color(warning);
        }
        .danger {
          color: color(danger);
        }
        span {
          font-size: 14px;
          font-weight: 600;
        }
        small {
          font-size: 12px;
          font-weight: 400;
          padding-right: 4px;
        }
      }
      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2px;
        max-width: 40%;
        width: 40%;
        overflow: hidden;
        &-numbers {
          span {
            font-size: 16px;
            font-weight: 600;
            text-overflow: ellipsis;
          }
        }
        &-lottery {
          span {
            font-size: 12px;
            font-weight: 600;
            text-overflow: ellipsis;
          }
        }
        &-type {
          span {
            font-size: 12px;
            font-weight: 400;
            text-overflow: ellipsis;
          }
        }
      }
      &-footer {
        max-width: 35%;
        width: 35%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: right;
        gap: 2px;
        &-amount {
          width: 100%;
          span {
            width: 100%;
            font-size: 14px;
            font-weight: 600;
            &.success {
              color: color(success);
            }
            &.warning {
              color: color(warning);
            }
            &.danger {
              color: color(danger);
            }
            .anticon {
              margin-right: 6px;
            }
          }
          &:first-child {
            span {
              font-size: 16px;
            }
          }
        }
      }
    }
    &-actions {
      width: 100%;
      margin-top: 10px;
      .ant-btn-group {
        width: 100%;
        .more-info-button,
        .view-button,
        .cancel-button {
          width: 100%;
        }
      }
    }
  }
}
</style>
